import React from 'react';
import {Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles, Typography} from "@material-ui/core";
import {Link} from "gatsby";
import Image from 'gatsby-image';

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardActionArea: {
        height: '100%',
    },
    card: {
        height: '100%',
        minHeight: '320px',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flexGrow: 1,
    },
    button: {
        textAlign: 'left'
    },
}));

export default function RechtsgebietCard({rechtsgebiet, categoryPath}) {
    const classes = useStyles();
    const toPath = categoryPath ? `/${categoryPath}/${rechtsgebiet.slug}` : `/${rechtsgebiet.slug}`
    return (
        <Grid item key={rechtsgebiet.slug} xs={12} sm={6} md={4}>
            <CardActionArea
                className={classes.cardActionArea}
                component={Link}
                href={toPath}
                to={toPath}
            >
                <Card className={classes.card}>
                    <CardMedia
                    >
                        <Image fluid={rechtsgebiet.cardImage.fluid}/>
                    </CardMedia>

                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {rechtsgebiet.title}
                        </Typography>
                        <Typography>
                            {rechtsgebiet.summary?.summary}
                        </Typography>
                    </CardContent>
                </Card>
            </CardActionArea>
        </Grid>
    );
}
